<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
        <b-input-group
          class="mb-3"
        >
          <!-- <b-form-input placeholder="ค้นหา"></b-form-input>
          <b-input-group-append>
            <b-button size="sm" text="Button" variant="primary"><i class="fas fa-search"></i> ค้นหา</b-button>
          </b-input-group-append> -->
        </b-input-group>

        <b-row class="px-2">
          <b-col
           v-for="(item,index) in gameTab"
           v-bind:key="index"
           cols=6 sm=4 class="px-1 py-1"
          >
            <b-button v-if="active === item.page" block variant="primary" class="px-0" :to=item.page>{{ item.name }}</b-button>
            <b-button v-else block variant="outline-primary" class="px-0" :to=item.page>{{ item.name }}</b-button>
          </b-col>
        </b-row>

        <b-row class="px-2">
          <b-col
          v-for="(item,index) in getGames.games"
          v-bind:key="index"
          cols="4" sm=3 md=2
          class="px-1"
          >
            <router-link :to="`/member/games/slot/${item.games_provider_id}`" >
              <div class="mt-2 box-new-top img-cuesor-pointer" sty>
                <div class="card">
                  <img class="card-img lightSweep" :src="require('@/assets/images/casino/icon/slot/'+item.games_provider_id+'.png')" alt="Card image">
                  <!-- <b-button  variant="outline-primary btn-violet btn-round">เข้าเล่น</b-button> -->
                  <strong class="text-center text-primary">{{ item.games_provider_id }}</strong>
                </div>
              </div>
            </router-link>
          </b-col>
        </b-row>

      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  async mounted () {
    if (process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim() === 'false') {
      this.$router.push('/member')
    }
    try {
      await this.getGamesList({
        type: 1
      })
    } catch (error) {
    }
  },
  computed: {
    ...mapGetters([
      'getGames'
    ])
  },
  methods: {
    ...mapActions(['getGamesList', 'getGamesUrl', 'setGameDepositCreditAll'])
  },
  data () {
    return {
      gameTab: [
        { name: 'รวมสล็อต', page: '/member/games/' },
        { name: 'คาสิโน', page: '/member/games/casino' },
        { name: 'เกมส์ยิงปลา', page: '/member/games/fisher' },
        { name: 'กีฬา', page: '/member/games/sport' }
        // { name: 'VS-Sport', page: '/member/games/vs_sport' }
      ],
      active: '/member/games/'
    }
  }
}
</script>
<style>
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff0;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
.btn-round {
    border-radius: 50px
 !important;
}
.btn-violet {
    background: #8b008b;
    color: white;
}
.img-cuesor-pointer {
  cursor: pointer;
}
.lightSweep {
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: none 0% 0% / auto repeat scroll padding-box border-box
    rgba(0, 0, 0, 0); */
  overflow: hidden;
  /* -webkit-filter: brightness(1.5); */
  -webkit-mask-size: 300% 200%;
  -webkit-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -webkit-transition: -webkit-mask-position;
  -webkit-animation-timing-function: linear;
  -webkit-transform: translate3d(0, 0, 0);
  /* -moz-filter: brightness(1.5); */
  -moz-mask-size: 300% 200%;
  -moz-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -moz-transition: -webkit-mask-position;
  -moz-animation-timing-function: linear;
  -moz-transform: translate3d(0, 0, 0);
  /* -o-filter: brightness(1.5); */
  -o-mask-size: 300% 200%;
  -o-mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  -o-transition: -webkit-mask-position;
  -o-animation-timing-function: linear;
  -o-transform: translate3d(0, 0, 0);
  /* filter: brightness(1.5); */
  mask-size: 300% 200%;
  mask-image: -webkit-linear-gradient(
    -45deg,
    rgb(193 193 193) 47%,
    rgb(193 193 193 / 75%) 50%,
    rgb(181 180 180) 53%
  );
  transition: -webkit-mask-position;
  animation-timing-function: linear;
  transform: translate3d(0, 0, 0);
  transition-duration: 2s;
  animation: move 4s ease-out infinite;
}
@keyframes move {
  from {
    -webkit-mask-position: 150% 0px;
    -moz-mask-position: 150% 0px;
    -o-mask-position: 150% 0px;
    mask-position: 150% 0px;
  }
  to {
    -webkit-mask-position: 0% 0px;
    -moz-mask-position: 0% 0px;
    -o-mask-position: 0% 0px;
    mask-position: 0% 0px;
  }
}

.btn-primary {
    background: linear-gradient( 118deg, rgb(26 74 173) 0%, rgb(13 171 254 / 55%) 100%, rgb(0 0 0 / 72%) 100%);
    box-shadow: 0px 0px 5px #055dd3cc;
}
</style>
